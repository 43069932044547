import React, { useState } from 'react';
import { Form, Input, Button, notification, Row, Col, Select } from 'antd';
import { activesApi } from '../apiCalls/activesApi';

const { Option } = Select;

const AddActiveForm: React.FC = () => {
  const [vrnUnit, setVrnUnit] = useState('mg');
  const [ulUnit, setUlUnit] = useState('mg/day');
  const [solidsUnit, setSolidsUnit] = useState('g/Kg');
  const [liquidUnit, setLiquidUnit] = useState('g/L');
  const [syrupUnit, setSyrupUnit] = useState('g/Kg');
  const [rdiUnit, setRdiUnit] = useState('g/day');

  const onFinish = async (values: any) => {
    const activeData = {
      ...values,
      vrn: values.vrn ? Number(values.vrn) : null,
      vrnUnit,
      ul: values.ul ? Number(values.ul) : null,
      ulUnit,
      limitSolids: values.solidsLimit ? Number(values.solidsLimit) : null,
      limitSolidsUnit: solidsUnit,
      limitLiquids: values.liquidLimit ? Number(values.liquidLimit) : null,
      limitLiquidsUnit: liquidUnit,
      limitSyrups: values.syrupLimit ? Number(values.syrupLimit) : null,
      limitSyrupsUnit: syrupUnit,
      recDairyIntake: values.rdi ? Number(values.rdi) : null,
      recDairyIntakeUnit: rdiUnit,
    };

    const result = await activesApi.createActive(activeData);
    if (result) {
      notification.success({
        message: 'Active Created',
        description: 'The active has been created successfully.',
        duration: 3,
      });
    } else {
      notification.error({
        message: 'Error al Crear',
        description: 'Hubo un error al crear el activo.',
        duration: 3,
      });
    }
  };

  return (
      <div style={{ padding: 24 }}>
        <h2>Add new active</h2>
        <Form layout="vertical" onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input placeholder="Enter the name" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="I.U. Claim" name="iuClaim">
                <Input placeholder="Enter I.U. Claim" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="VRN" name="vrn">
                <Input
                    type="number"
                    placeholder="Enter VRN"
                    addonAfter={
                      <Select
                          placeholder="Unit"
                          style={{ width: 90 }}
                          value={vrnUnit}
                          onChange={(value) => setVrnUnit(value)}
                      >
                        <Option value="mg">mg</Option>
                        <Option value="g">g</Option>
                        <Option value="ug">ug</Option>
                      </Select>
                    }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="UL" name="ul">
                <Input
                    type="number"
                    placeholder="Enter UL"
                    addonAfter={
                      <Select
                          placeholder="Unit"
                          style={{ width: 90 }}
                          value={ulUnit}
                          onChange={(value) => setUlUnit(value)}
                      >
                        <Option value="mg/day">mg/day</Option>
                        <Option value="g/day">g/day</Option>
                        <Option value="ug/day">ug/day</Option>
                      </Select>
                    }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Solids Limits" name="solidsLimit">
                <Input
                    type="number"
                    placeholder="Enter Solids Limits"
                    addonAfter={
                      <Select
                          value={solidsUnit}
                          onChange={setSolidsUnit}
                          style={{ width: 90 }}
                      >
                        <Option value="g/Kg">g/Kg</Option>
                        <Option value="mg/Kg">mg/Kg</Option>
                        <Option value="ug/Kg">ug/Kg</Option>
                      </Select>
                    }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Liquid Limits" name="liquidLimit">
                <Input
                    type="number"
                    placeholder="Enter Liquid Limits"
                    addonAfter={
                      <Select
                          value={liquidUnit}
                          onChange={setLiquidUnit}
                          style={{ width: 90 }}
                      >
                        <Option value="g/L">g/L</Option>
                        <Option value="mg/L">mg/L</Option>
                        <Option value="ug/L">ug/L</Option>
                      </Select>
                    }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Syrup/Gel Limits" name="syrupLimit">
                <Input
                    type="number"
                    placeholder="Enter Syrup Limits"
                    addonAfter={
                      <Select
                          value={syrupUnit}
                          onChange={setSyrupUnit}
                          style={{ width: 90 }}
                      >
                        <Option value="g/Kg">g/Kg</Option>
                        <Option value="mg/Kg">mg/Kg</Option>
                        <Option value="ug/Kg">ug/Kg</Option>
                      </Select>
                    }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="RDI (Rec. Daily Intake)" name="rdi">
                <Input
                    type="number"
                    placeholder="Enter RDI"
                    addonAfter={
                      <Select
                          value={rdiUnit}
                          onChange={setRdiUnit}
                          style={{ width: 90 }}
                      >
                        <Option value="g/day">g/day</Option>
                        <Option value="mg/day">mg/day</Option>
                        <Option value="ug/day">ug/day</Option>
                      </Select>
                    }
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Active
            </Button>
          </Form.Item>
        </Form>
      </div>
  );
};

export default AddActiveForm;

import { apiClient } from './apiClient';

export interface Active {
  id: string;
  name: string;
  vrn: number | null;
  vrnUnit: string;
  iuClaim: number | null;
  ul: number | null;
  ulUnit: string;
  solidsLimit: any,
  liquidLimit: any,
  syrupLimit: any,
  rdi: any,
}

export const activesApi = {
  getActives: async (): Promise<Active[]> => {
    try {
      const response = await apiClient.get<Active[]>('/lists/active');
      return response.data;
    } catch (error) {
      console.error('Error fetching actives:', error);
      return [];
    }
  },
  getActiveById: async (id: string): Promise<Active | null> => {
    try {
      const response = await apiClient.get<Active>(`/active/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching active by ID:', error);
      return null;
    }
  },
  createActive: async (activeData: Partial<Active>): Promise<Active | null> => {
    try {
      const response = await apiClient.post<Active>('/active', activeData);
      return response.data;
    } catch (error) {
      console.error('Error creating active:', error);
      return null;
    }
  },
  updateActive: async (id: string, activeData: Partial<Active>): Promise<Active | null> => {
    try {
      const response = await apiClient.post<Active>(`/active/${id}/update`, activeData);
      return response.data;
    } catch (error) {
      console.error('Error updating active:', error);
      return null;
    }
  },
  deleteActive: async (id: string): Promise<boolean> => {
    try {
      await apiClient.delete(`/active/${id}/delete`);
      return true;
    } catch (error: any) {
      if (error.response && error.response.status === 403) {
        console.error('Cannot delete active, it is used in a product/ingredient.');
      } else {
        console.error('Error deleting active:', error);
      }
      return false;
    }
  },
};
